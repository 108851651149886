<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <button
          class="btn mx-1 btn-timeseries"
          @click="Filter('thisMonth')"
          v-bind:class="{
            'btn-info': btnActive.month,
            'btn-outline-info': btnActive.month == false,
          }"
        >
          Bulan Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="Filter('lastMonth')"
          v-bind:class="{
            'btn-info': btnActive.lastMonth,
            'btn-outline-info': btnActive.lastMonth == false,
          }"
        >
          Bulan Kemarin
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="Filter('thisYear')"
          v-bind:class="{
            'btn-info': btnActive.year,
            'btn-outline-info': btnActive.year == false,
          }"
        >
          Tahun Ini
        </button>
        <button
          class="btn mx-1 btn-timeseries"
          @click="periodFilter()"
          v-bind:class="{
            'btn-info': btnActive.period,
            'btn-outline-info': btnActive.period == false,
          }"
        >
          Periode
        </button>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end">
          <span class="symbol symbol-50 symbol-light-primary mr-2">
            <button
              class="btn btn-success mr-1 d-inline"
              v-b-tooltip.hover
              title="Table"
              @click="$router.push('/report/pharmacy-expense-report')"
            >
              <i class="fas fa-table px-0"></i> Table
            </button>
          </span>
        </div>
      </div>
    </div>

    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        card-spacer
        flex-grow-1
      "
    >
      <div class="col-12 col-md-9" >
        <!-- Filter -->
        <div class="row align-items-center justify-content-start mt-n3" v-if="tooltipPeriod == 'custom'">
          <div class="col-md-12">
            <div class="row">
              <span class="d-none d-sm-block">
                <div class="row">
                  <div class="col">
                    <b-input-group>
                      <template #prepend>
                        <!-- <button
                          class="btn btn-success mr-1 d-inline"
                          v-b-tooltip.hover
                          title="Table"
                          @click="
                            $router.push('/report/pharmacy-expense-report')
                          "
                        >
                          <i class="fas fa-table px-0"></i>
                        </button> -->
                        <b-button squared @click="resetFilter" variant="danger"
                          >Reset</b-button
                        >
                        <b-button
                          squared
                          @click="generalFilter"
                          variant="success"
                          >Cari</b-button
                        >
                      </template>
                      <b-form-input
                        v-model="filter.start_date"
                        type="text"
                        placeholder="Tanggal Awal"
                        autocomplete="off"
                        readonly
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="filter.start_date"
                          button-only
                          right
                          reset-button
                          locale="id"
                          aria-controls="example-input"
                        ></b-form-datepicker>
                      </b-input-group-append>
                      <b-button squared variant="light">s/d</b-button>
                      <b-form-input
                        v-model="filter.end_date"
                        type="text"
                        placeholder="Tanggal Akhir"
                        autocomplete="off"
                        readonly
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="filter.end_date"
                          button-only
                          right
                          reset-button
                          locale="id"
                          aria-controls="example-input"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
              </span>
            </div>
            <span class="d-block d-sm-none">
              <div class="row">
                <div class="col-12 pb-1">
                  <b-input-group>
                    <template #prepend>
                      <button
                        class="btn btn-success mr-1 d-inline"
                        v-b-tooltip.hover
                        title="Table"
                        @click="$router.push('/report/pharmacy-expense-report')"
                      >
                        <i class="fas fa-table px-0"></i>
                      </button>
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                      <b-button squared @click="generalFilter" variant="success"
                        >Cari</b-button
                      >
                    </template>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="col-12 pt-0">
                  <b-input-group>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column text-right">
        <span
          class="text-dark-75 font-weight-bolder font-size-h3"
          >Rp
          <!-- {{ parseInt(sum[0].display_amount).toLocaleString("id-ID") }}</span -->
          {{ parseInt(sum).toLocaleString("id-ID") }}</span
        >
        <span class="text-muted font-weight-bold mt-2">Pengeluaran Apotik</span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="series"
          type="bar"
          width="100%"
          height="350"
        ></apexchart>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      series: [
        {
          name: "Jumlah :",
          data: [],
        },
      ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,

      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: true,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "thisMonth",

      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      tooltipPeriod: "thisMonth",
      
      // charOptions
      chartOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 1,
        },
        colors: ["#245590"],
        tooltip: {
          y: {
            formatter: function (val) {
              // return val;
              return parseInt(val).toLocaleString("id-ID");
            },
          },
        },
        
      },
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      this.getData();
      mapGetters(["layoutConfig"]);

      if (tool == "thisMonth") {
        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "thisMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (tool == "lastMonth") {
        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (tool == "thisYear") {
        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "thisYear";

        // hide period
        this.onShowPeriod = false;
      }
    },
    generalFilter() {
      this.tooltipPeriod = "custom";
      this.chartLoad = false;
      this.getData();
      mapGetters(["layoutConfig"]);
    },
    periodFilter() {
      this.tooltipPeriod = "custom";

      // set btn Active
      this.btnActive.days = false;
      this.btnActive.month = false;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = true;
    },
    resetFilter() {
      this.chartLoad = false;
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.getData();
      mapGetters(["layoutConfig"]);
    },

    async getSummary() {
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      this.sum = await module.get(
        "pharmacy-expense-reports-summary-amount-chart",
        `${filterParams}`
      );
      if (this.sum.length == 0) {
        this.sumLoad = false;
      } else {
        this.sumLoad = true;
      }
    },

    async getData() {
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get(
        "pharmacy-expense-chart",
        `${filterParams}`
      );
      // let category = await module.get('expense-categories')

      this.series[0].data = response.map((response) =>
        parseFloat(response.display_amount)
      );
      window.arrData = response.map((response) =>
        String(response.pharmacy_expense_category_name != "" ? response.pharmacy_expense_category_name : "Tanpa Ketegori" ) 
      );
      this.chartLoad = true;
      // this.getSummary();

      this.sum = 0
      this.series[0].data.forEach(el => {
        this.sum = this.sum += el
      });


      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
            title: {
              text: "",
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    // this.getSummary();
    this.getData();
    if (this.chartLoad == true) {
    }
    // reference; kt_stats_widget_12_chart
  },
};
</script>
